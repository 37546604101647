import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EmailIcon from '@mui/icons-material/Email';
import HistoryIcon from '@mui/icons-material/History';

import { toast } from 'react-toastify';

import SignatureCanvas from 'react-signature-canvas';

import Dropdown from '../components/Dropdown';
import StripedDataGrid from '../components/StripedDataGrid';
import CustomerReportHistoryList from './components/CustomerReportHistoryList';
import GridToolbarQuickFilterCustom from '../components/GridToolbarQuickFilterCustom';

import Config from '../components/Config';

const Customers = () => {
	const theme = useTheme();

	const [customerData, setCustomerData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [dataGridPageSize, setDataGridPageSize] = useState(25);
	const [reportingSIDataGridPageSize, setReportingSIDataGridPageSize] = useState(25);
	const [reportingTSDataGridPageSize, setReportingTSDataGridPageSize] = useState(25);

	const [deleteCustomerID, setDeleteCustomerID] = useState();

	const [isAddingRecord, setIsAddingRecord] = useState(false);
	const [editCustomerID, setEditCustomerID] = useState();
	const [editCustomerData, setEditCustomerData] = useState();

	const [loadData, setLoadData] = useState(true);

	const [openEditModal, setOpenEditModal] = useState(false);
	const [openSentReportsHistoryModal, setOpenSentReportsHistoryModal] = useState(false);

	const [editReportingSIData, setEditReportingSIData] = useState([]);
	const [editReportingTSData, setEditReportingTSData] = useState([]);
	const [editReportingSIDataSelection, setEditReportingSIDataSelection] = useState([]);
	const [editReportingTSDataSelection, setEditReportingTSDataSelection] = useState([]);
	const [editReportingCustomerData, setEditReportingCustomerData] = useState();
	const [openReportingModal, setOpenReportingModal] = useState(false);
	const [reportTabValue, setReportTabValue] = useState(0);

	const handleOpenAddEditModal = () => {
		setIsAddingRecord(true);
		setEditCustomerData({
			customer_id: 1,
		});
		setOpenEditModal(true);
  };
	const handleOpenEditModal = (id, data) => {
		setIsAddingRecord(false);
		setEditCustomerID(id);

		let dataInstance = JSON.parse(JSON.stringify(data)); // Deep copy data
		setEditCustomerData(dataInstance);
		setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
		setEditCustomerID();
		setEditCustomerData();
    setOpenEditModal(false);
  };

	const handleOpenReportingModal = (id, data) => {
		setEditCustomerID(id);
		populateReportingData(id);
		setEditReportingCustomerData(data);
		setOpenReportingModal(true);
  };
  const handleCloseReportingModal = () => {
		setEditCustomerID();
		setEditReportingSIData([]);
		setEditReportingTSData([]);
		setEditReportingCustomerData({});
    setOpenReportingModal(false);
  };

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (id) => {
		setDeleteCustomerID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteCustomerID();
    setOpenDeleteDialog(false);
  };

	const [openSendReportDialog, setOpenSendReportDialog] = useState(false);
	const [showSendingLoadingProgress, setShowSendingLoadingProgress] = useState(false);
  const handleOpenSendReportDialog = () => {
    setOpenSendReportDialog(true);
  };
  const handleCloseSendReportDialog = () => {
    setOpenSendReportDialog(false);
  };

  const handleCancelReport = useCallback((id, reportType) => {
		// Report type
		// 1 - Service Inspection Report
		// 2 - Treatment Service Report
		if(reportType === 1){
			let data = editReportingSIData;
			if(data[data.findIndex(x => x.id == id)].monthly_report_sent === 0){
				data[data.findIndex(x => x.id == id)].monthly_report_sent = 2;
				data[data.findIndex(x => x.id == id)].cancelReport.checked = true;
			} else {
				data[data.findIndex(x => x.id == id)].monthly_report_sent = 0;
				data[data.findIndex(x => x.id == id)].cancelReport.checked = false;
			}
			setEditReportingSIData([...data]);
		} else if(reportType === 2) {
			let data = editReportingTSData;
			if(data[data.findIndex(x => x.id == id)].monthly_report_sent === 0){
				data[data.findIndex(x => x.id == id)].monthly_report_sent = 2;
				data[data.findIndex(x => x.id == id)].cancelReport.checked = true;
			} else {
				data[data.findIndex(x => x.id == id)].monthly_report_sent = 0;
				data[data.findIndex(x => x.id == id)].cancelReport.checked = false;
			}
			setEditReportingTSData([...data]);
		}
	}, [editReportingSIData, editReportingTSData]);

	let navigate = useNavigate();

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const buttonStyle = {
		textTransform: 'none',
		width: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const getCustomerData = () => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'customer/', options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			// Clone and store the original data
			setCustomerData(JSON.parse(JSON.stringify(res)));
			let originalData = JSON.parse(JSON.stringify(res));

			// Format data for table display
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].customer_id;

				let reportingData = {
					reporting_include_images: arr[index].reporting_include_images
				};
				// Buttons
				arr[index].button = (
					<>
						<IconButton aria-label="edit" color="warning" size="small" onClick={() => handleOpenReportingModal(arr[index].customer_id, reportingData)}>
							<AssessmentIcon fontSize="small"/>
						</IconButton>
						<IconButton aria-label="edit" color="info" size="small" onClick={() => handleOpenEditModal(arr[index].customer_id, originalData[index])}>
							<EditIcon fontSize="small"/>
						</IconButton>
						<IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteDialog(arr[index].customer_id)}>
							<DeleteIcon fontSize="small"/>
						</IconButton>
					</>
				)
			});

			// Display the table data
			setTableData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const populateReportingData = (customerID) => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		// Populate service inspection report data
		fetch(Config.server_url + `reporting/serviceinspection?unsent_report_only=true&customer_id=${customerID}`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].service_inspection_report_id;

				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;

				arr[index].cancelReport = {
					id: arr[index].id,
					reportType: 1,
					checked: false,
				};
			});

			setEditReportingSIDataSelection(res.filter(x => x.send_monthly_report).map(x => x.id));
			setEditReportingSIData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

		// Populate treatment service report data
		fetch(Config.server_url + `reporting/treatmentservice?unsent_report_only=true&customer_id=${customerID}`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].treatment_service_report_id;

				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;

				arr[index].cancelReport = {
					id: arr[index].id,
					reportType: 2,
					checked: false,
				};
			});

			setEditReportingTSDataSelection(res.filter(x => x.send_monthly_report).map(x => x.id));
			setEditReportingTSData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const editReporting = async (e) => {
		e.preventDefault();

		try {
			// Update customer reporting settings
			let options = {
				method: "PUT",
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify(editReportingCustomerData)
			};

			let res = await fetch(Config.server_url + 'customer/' + editCustomerID, options)
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Update reporting
			// Service inspection report
			options = {
				method: "PUT",
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify(editReportingSIData)
			};

			res = await fetch(Config.server_url + 'reporting/serviceinspection/all/email', options)
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Treatment Service report
			options = {
				method: "PUT",
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify(editReportingTSData)
			};

			res = await fetch(Config.server_url + 'reporting/treatmentservice/all/email', options)
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited reporting</div>);
			setLoadData(true);
			handleCloseReportingModal();
		} catch (err) {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		}
	};

	const sendReports = (e) => {
		e.preventDefault();
		handleCloseSendReportDialog();
		setShowSendingLoadingProgress(true);

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
		};

		fetch(Config.server_url + 'email/report/customer/' + editCustomerID, options)
		.then(res => {
			setShowSendingLoadingProgress(false);
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully emailed customer.</div>);
			setLoadData(true);
			handleCloseReportingModal();
		})
		.catch(err => {
			setShowSendingLoadingProgress(false);
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const addCustomer = (e) => {
		e.preventDefault();

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editCustomerData)
		};

		fetch(Config.server_url + 'customer/', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully added customer</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const editCustomer = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editCustomerData)
		};

		fetch(Config.server_url + 'customer/' + editCustomerID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited customer</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const deleteCustomer = () => {

		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'customer/' + deleteCustomerID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted customer</div>);
			setLoadData(true);
			handleCloseDeleteDialog();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const columns = [
		{
	    field: 'customer_id',
	    headerName: 'ID',
			flex: 0.5,
	  },
		{
	    field: 'customer_name',
	    headerName: 'Name',
			minWidth: 150,
			flex: 1,
	  },
	  {
	    field: 'customer_email',
	    headerName: 'Email',
			minWidth: 200,
			flex: 1,
	  },
		{
	    field: 'button',
	    headerName: '',
			sortable: false,
			flex: 1,
			align: 'right',
			minWidth: 100,
	    renderCell: (field) => (
				<>
						{field.value}
	      </>
	    ),
	  },
	];

	const reportColumns = [
		{
	    field: 'serial_no',
	    headerName: 'Serial No',
	  },
		{
	    field: 'address_dg',
	    headerName: 'Address',
			flex: 1,
			minWidth: 150,
	  },
		{
	    field: 'cancelReport',
	    headerName: 'Cancel Report',
			headerAlign: 'right',
			sortable: false,
			align: 'right',
			flex: 0.5,
			minWidth: 50,
			renderCell: (params) => (
				<>
					<Checkbox
						icon={<CancelOutlinedIcon />}
						checkedIcon={<CancelIcon />}
						color="danger"
						checked={params.row.cancelReport.checked}
						onChange={(e) => handleCancelReport(params.row.cancelReport.id, params.row.cancelReport.reportType)}/>
	      </>
	    ),
	  },
	];

	useEffect(() => {
		if(loadData) {
			getCustomerData();
			setLoadData(false);
		}
	});

	return (
		<>
		<Grid
			container
			spacing={2}
			>
			<Grid container item xs={12}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" noWrap component="div">
						<b>Customers</b>
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} justifyContent="flex-end">
					<Button
						color="success"
						variant="pill"
						disableElevation
						sx={buttonStyle}
						onClick={(e) => handleOpenAddEditModal()}
						>
						<AddIcon fontSize="small"/> Add Customer
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div style={{ height: '80vh' }}>
					<StripedDataGrid
						rows={tableData}
						columns={columns}
						pageSize={dataGridPageSize}
						onPageSizeChange={(newPageSize) =>
        			setDataGridPageSize(newPageSize)
    				}
						pagination
						rowsPerPageOptions={[25, 50, 100]}
						getRowHeight={() => "auto"}
						sx={{
							'.MuiDataGrid-columnSeparator': {
								display: 'none',
							},
							'&.MuiDataGrid-root': {
								border: 'none',
							},
							'& .MuiDataGrid-cell': {
								py: '5px',
							},
						}}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						components={{ Toolbar: GridToolbarQuickFilterCustom }}
					/>
				</div>
			</Grid>
		</Grid>

		{/* Delete Dialog */}
		<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" disableElevation onClick={deleteCustomer} sx={{textTransform: 'none'}}>Delete</Button>
        <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
          Close
        </Button>
      </DialogActions>
    </Dialog>

		{/* Send Report Dialog */}
		<Dialog
        open={openSendReportDialog}
        onClose={handleCloseSendReportDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to send all upcoming reports?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <b>Note:</b> If you have any unsaved changes, please save them before attempting to send.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="warning" variant="contained" disableElevation onClick={sendReports} sx={{textTransform: 'none'}}><EmailIcon fontSize="small" sx={{mr: 1}}/> Send now</Button>
        <Button onClick={handleCloseSendReportDialog} autoFocus sx={{textTransform: 'none'}}>
          Close
        </Button>
      </DialogActions>
    </Dialog>

		{/* Add/Edit form */}
		<Modal
      open={openEditModal}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3}}>
          {(isAddingRecord) ? "Add Customer" : "Edit Customer"}
        </Typography>

				<Box
					component="form"
					autoComplete="true"
					onSubmit={(isAddingRecord) ? addCustomer : editCustomer}
				>
					<Grid
						container
						spacing={2}
						>
						<Grid item xs={12}>
							<TextField
								fullWidth
								required
								label="Name"
								type="text"
								placeholder="Name"
								autoComplete="off"
								value={(editCustomerData && editCustomerData.customer_name) ? editCustomerData.customer_name : ""}
								onInput={(e) => {
									editCustomerData.customer_name = e.target.value;
									setEditCustomerData((prev) => ({...prev, customer_name: editCustomerData.customer_name}))
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Email"
								type="email"
								placeholder="admin@example.com"
								autoComplete="off"
								value={(editCustomerData && editCustomerData.customer_email) ? editCustomerData.customer_email : ""}
								onInput={(e) => {
									editCustomerData.customer_email = e.target.value;
									setEditCustomerData((prev) => ({...prev, customer_email: editCustomerData.customer_email}))
								}}
							/>
						</Grid>
						<Grid container item xs={12} justifyContent="flex-end">
							<Button
								type="submit"
								color="success"
								variant="contained"
								disableElevation
								sx={{textTransform: 'none'}}
								>
								Submit
							</Button>
							<Button
								type="button"
								color="info"
								variant="contained"
								disableElevation
								sx={{textTransform: 'none', marginLeft: 1}}
								onClick={handleCloseEditModal}
								>
								Close
							</Button>
						</Grid>
					</Grid>
				</Box>
      </Paper>
    </Modal>

		{/* Reporting modal */}
		<Modal
      open={openReportingModal}
      onClose={handleCloseReportingModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={modalStyle}>
				{ (showSendingLoadingProgress) ?
					(
						<Grid container item justifyContent="center">
							<CircularProgress sx={{mr: 2}}/><p>Sending...</p>
						</Grid>
					) :
					(
						<>
						<Grid container sx={{mb: 2}}>
							<Grid item xs={12} md={5}>
				        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mr: 1}}>
				          {"Report Management"}
				        </Typography>
							</Grid>
							<Grid container item xs={12} md={7} justifyContent="flex-end">
								<Button
									type="button"
									color="success"
									variant="outlined"
									disableElevation
									sx={{textTransform: 'none', mr: 1}}
									onClick={() => setOpenSentReportsHistoryModal(true)}
									>
									<HistoryIcon fontSize="small" sx={{mr: 1}}/> View History
								</Button>
								<Button
									type="button"
									color="warning"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									onClick={() => handleOpenSendReportDialog()}
									>
									<EmailIcon fontSize="small" sx={{mr: 1}}/> Send reports now
								</Button>
							</Grid>
						</Grid>
						<Box
							component="form"
							autoComplete="true"
							onSubmit={editReporting}
						>
							<Grid
								container
								spacing={2}
								>
								<Grid item xs={12}>
									<FormControlLabel control={
										<Checkbox onChange={(e) => setEditReportingCustomerData((prev) => ({...prev, reporting_include_images: e.target.checked}))} />
										}
										checked={(editReportingCustomerData && editReportingCustomerData.reporting_include_images) ? true : false}
										label="Include images when sending reports?"
									/>
								</Grid>
								{
									/* TO RE-ENABLE ONCE SCHEDULE IS IMPLEMENTED
									<Grid item xs={12}>
										<Alert severity="info">Reports are automatically sent on the 25th of every month.</Alert>
									</Grid>
									*/
								}
								<Grid item xs={12} sx={{mt: 3}}>
									<Typography id="modal-modal-title" variant="h6" component="h6">
				            {"Upcoming Reporting"}
				          </Typography>
									<small style={{color: theme.palette.hint.main}}>Select the reports to be included in the monthly reporting</small>
								</Grid>
								<Grid item xs={12}>
									{/* Tabs */}
									<Box sx={{ width: '100%', mb: 4 }}>
										<Tabs
											value={reportTabValue}
											onChange={(event, newValue) => {
												setReportTabValue(newValue);
											}}
											variant="fullWidth"
											centered>
											<Tab label="Service Inspection Report" />
											<Tab label="Treatment Service Report" />
										</Tabs>
									</Box>

									{
										(reportTabValue === 0) ? (
											<div style={{ height: '500px' }}>
												<StripedDataGrid
													rows={editReportingSIData}
													columns={reportColumns}
													pageSize={reportingSIDataGridPageSize}
													onPageSizeChange={(newPageSize) =>
							        			setReportingSIDataGridPageSize(newPageSize)
							    				}
													pagination
													rowsPerPageOptions={[25, 50, 100]}
													checkboxSelection
													disableSelectionOnClick
													selectionModel={editReportingSIDataSelection}
													onSelectionModelChange={(selectionModel) => {
														for(let item of editReportingSIData){
															item.send_monthly_report = false;
														}

														for(let id of selectionModel){
															editReportingSIData[editReportingSIData.findIndex(x => x.id == id)].send_monthly_report = true;
														}
														setEditReportingSIData(editReportingSIData);
														setEditReportingSIDataSelection(selectionModel);
													}}
													sx={{
														'.MuiDataGrid-columnSeparator': {
															display: 'none',
														},
														'&.MuiDataGrid-root': {
															border: 'none',
														},
													}}
													getRowClassName={(params) =>
														params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
													}
												/>
											</div>
										) :
										(reportTabValue === 1) ? (
											<div style={{ height: '500px' }}>
												<StripedDataGrid
													rows={editReportingTSData}
													columns={reportColumns}
													pageSize={reportingTSDataGridPageSize}
													onPageSizeChange={(newPageSize) =>
							        			setReportingTSDataGridPageSize(newPageSize)
							    				}
													pagination
													rowsPerPageOptions={[25, 50, 100]}
													checkboxSelection
													disableSelectionOnClick
													selectionModel={editReportingTSDataSelection}
													onSelectionModelChange={(selectionModel) => {
														for(let item of editReportingTSData){
															item.send_monthly_report = false;
														}

														for(let id of selectionModel){
															editReportingTSData[editReportingTSData.findIndex(x => x.id == id)].send_monthly_report = true;
														}
														setEditReportingTSData(editReportingTSData);
														setEditReportingTSDataSelection(selectionModel);
													}}
													sx={{
														'.MuiDataGrid-columnSeparator': {
															display: 'none',
														},
														'&.MuiDataGrid-root': {
															border: 'none',
														},
													}}
													getRowClassName={(params) =>
														params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
													}
												/>
											</div>
										) : <></>
									}
								</Grid>
								<Grid item xs={12}>
									{
										(editReportingSIData.some(x => x.cancelReport.checked)) &&
										(
											<Alert severity="warning">One or more of your report(s) have been marked for cancellation. This will prevent the report(s) from being included in future reporting.</Alert>
										)
									}
								</Grid>
								<Grid container item xs={12} justifyContent="flex-end">
									<Button
										type="submit"
										color="success"
										variant="contained"
										disableElevation
										sx={{textTransform: 'none'}}
										>
										Submit
									</Button>
									<Button
										type="button"
										color="info"
										variant="contained"
										disableElevation
										sx={{textTransform: 'none', marginLeft: 1}}
										onClick={handleCloseReportingModal}
										>
										Close
									</Button>
								</Grid>
							</Grid>
						</Box>
						</>
					)
				}
      </Paper>
    </Modal>
		<CustomerReportHistoryList
			customerID={editCustomerID}
			open={openSentReportsHistoryModal}
			onCloseModal={() => setOpenSentReportsHistoryModal(false)}
		/>
		</>
	);
}

export default Customers;
