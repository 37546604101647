import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';

import { toast } from 'react-toastify';

import SignatureCanvas from 'react-signature-canvas';

import Dropdown from '../components/Dropdown';
import StripedDataGrid from '../components/StripedDataGrid';
import GridToolbarQuickFilterCustom from '../components/GridToolbarQuickFilterCustom';

import Config from '../components/Config';

const Items = () => {
	const theme = useTheme();

	let sigCanvas = useRef({});

	const [itemData, setItemData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [dataGridPageSize, setDataGridPageSize] = useState(25);

	const [deleteItemID, setDeleteItemID] = useState();

	const [isAddingRecord, setIsAddingRecord] = useState(false);
	const [editItemID, setEditItemID] = useState();
	const [editItemData, setEditItemData] = useState();

	const [loadData, setLoadData] = useState(true);

	const [openEditModal, setOpenEditModal] = useState(false);

	const handleOpenAddEditModal = () => {
		setIsAddingRecord(true);
		setEditItemData({
			items: [],
		});
		setOpenEditModal(true);
  };
	const handleOpenEditModal = (id, data) => {
		setIsAddingRecord(false);
		setEditItemID(id);

		let dataInstance = JSON.parse(JSON.stringify(data)); // Deep copy data
		setEditItemData(dataInstance);
		setOpenEditModal(true);
		sigCanvas = [];
  };
  const handleCloseEditModal = () => {
		setEditItemID();
		setIsAddingRecord(false);
		setEditItemData();
    setOpenEditModal(false);
  };

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (id) => {
		setDeleteItemID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteItemID();
    setOpenDeleteDialog(false);
  };

	let navigate = useNavigate();

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const buttonStyle = {
		textTransform: 'none',
		width: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const getItemData = () => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'item/', options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			// Clone and store the original data
			setItemData(JSON.parse(JSON.stringify(res)));
			let originalData = JSON.parse(JSON.stringify(res));

			// Format data for table display
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].item_id;

				// Customer
				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;

				arr[index].customer = {
					name: arr[index].customer_name,
					address: addressStr,
				}

				// Buttons
				arr[index].button = (
					<>
						<IconButton aria-label="edit" color="info" size="small" onClick={() => handleOpenEditModal(arr[index].item_id, originalData[index])}>
							<EditIcon fontSize="small"/>
						</IconButton>
						<IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteDialog(arr[index].item_id)}>
							<DeleteIcon fontSize="small"/>
						</IconButton>
					</>
				)
			});

			// Display the table data
			setTableData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const addItem = (e) => {
		e.preventDefault();

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editItemData)
		};

		fetch(Config.server_url + 'item/', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully added item</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const editItem = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editItemData)
		};

		fetch(Config.server_url + 'item/' + editItemID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited item</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const deleteItem = () => {

		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'item/' + deleteItemID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted item</div>);
			setLoadData(true);
			handleCloseDeleteDialog();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const columns = [
		{
	    field: 'item_id',
	    headerName: 'ID',
			flex: 0.5,
	  },
	  {
	    field: 'customer',
	    headerName: 'Customer',
			flex: 1,
			minWidth: 150,
			renderCell: (params) => (
	      <div>
	        <b>{params.row.customer.name}</b><br/>
	        {params.row.customer.address}
	      </div>
	    ),
			valueGetter: (params) => `${params.row.customer.name} ${params.row.customer.address}`
	  },
		{
	    field: 'item_name',
	    headerName: 'Item',
			flex: 1,
			minWidth: 150,
	  },
		{
	    field: 'button',
	    headerName: '',
			sortable: false,
			flex: 1,
			align: 'right',
			minWidth: 75,
	    renderCell: (field) => (
				<>
						{field.value}
	      </>
	    ),
	  },
	];

	useEffect(() => {
		if(loadData) {
			getItemData();
			setLoadData(false);
		}
	});

	return (
		<>
		<Grid
			container
			spacing={2}
			>
			<Grid container item xs={12}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" noWrap component="div">
						<b>Items</b>
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} justifyContent="flex-end">
					<Button
						color="success"
						variant="pill"
						disableElevation
						sx={buttonStyle}
						onClick={(e) => handleOpenAddEditModal()}
						>
						<AddIcon fontSize="small"/> Add Item
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div style={{ height: '80vh' }}>
					<StripedDataGrid
						rows={tableData}
						columns={columns}
						pageSize={dataGridPageSize}
						onPageSizeChange={(newPageSize) =>
        			setDataGridPageSize(newPageSize)
    				}
						pagination
						rowsPerPageOptions={[25, 50, 100]}
						getRowHeight={() => "auto"}
						sx={{
							'.MuiDataGrid-columnSeparator': {
								display: 'none',
							},
							'&.MuiDataGrid-root': {
								border: 'none',
							},
						}}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						components={{ Toolbar: GridToolbarQuickFilterCustom }}
					/>
				</div>
			</Grid>
		</Grid>

		{/* Delete Dialog */}
		<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Reports that include this item will have the item removed from their list.<br/><b>This action cannot be undone.</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" disableElevation onClick={deleteItem} sx={{textTransform: 'none'}}>Delete</Button>
          <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

			{/* Add/Edit form */}
			<Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3}}>
            {(isAddingRecord) ? "Add Item" : "Edit Item"}
          </Typography>

					<Box
						component="form"
						autoComplete="true"
						onSubmit={(isAddingRecord) ? addItem : editItem}
					>
						<Grid
							container
							spacing={2}
							>
							<Grid item xs={12}>
								<Dropdown
									dataUrl="customer"
									label="Customer"
									keyField="customer_id"
									valueField="customer_name"
									fullWidth
									value={(editItemData && editItemData.customer_id) ? editItemData.customer_id : ""}
									onChange={(e) => setEditItemData((prev) => ({...prev, customer_id: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12}>
								{
									(editItemData) && (
									<Dropdown
										dataUrl={"location/customer/" + editItemData.customer_id}
										label="Location"
										keyField="location_id"
										valueField="address"
										fullWidth
										value={(editItemData && editItemData.location_id) ? editItemData.location_id : ""}
										onChange={(e) => setEditItemData((prev) => ({...prev, location_id: e.target.value}))}
										/>
									)
								}
							</Grid>
							{
								(isAddingRecord) ?
								(
									<>
									{/* ADDING NEW */}
									<Grid item xs={8}>
										<Typography variant="label" component="label" sx={{color: theme.palette.hint.main}}>
											Items
										</Typography>
									</Grid>
									<Grid container item xs={4} justifyContent="flex-end">
									{
										(
											<Button
												color="success"
												disableElevation
												sx={{textTransform: 'none'}}
												onClick={(e) => {
													editItemData.items.push({
														item_name: "",
														is_service_inspection_report_item: true,
														is_treatment_service_report_item: true,
													});
													setEditItemData((prev) => ({...prev, items: editItemData.items}))
												}}
												>
												<AddIcon fontSize="small"/> Add New
											</Button>
										)
									}
									</Grid>
									{
										editItemData.items.map((item, index) =>
										<Paper key={index} elevation={0} sx={{border: '0.5px solid #BFBABA', mb: 2, p: 2, width: '100%'}}>
											<Grid container>
												<Grid item xs={12} md={10}>
													<TextField
														fullWidth
														required
														label="Item name"
														type="text"
														placeholder="Item name"
														autoComplete="off"
														value={(item.item_name) ? item.item_name : ""}
														onInput={(e) => {
															editItemData.items[index].item_name = e.target.value;
															setEditItemData((prev) => ({...prev, items: editItemData.items}))
														}}
													/>
												</Grid>
												<Grid item xs={12} md={2}>
													<Button
														aria-label="delete"
														color="error"
														size="small"
														variant="contained"
														disableElevation
														fullWidth
														sx={{height:"100%"}}
														onClick={() => {
															editItemData.items.splice(index, 1);
															setEditItemData((prev) => ({...prev, items: editItemData.items}))
														}}
													>
														<CancelIcon fontSize="small"/>
													</Button>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<FormGroup row={true}>
													<FormControlLabel control={
														<Checkbox onChange={(e) => {
															editItemData.items[index].is_service_inspection_report_item = e.target.checked;
															setEditItemData((prev) => ({...prev, items: editItemData.items}))
														}} />
														}
														checked={(item.is_service_inspection_report_item) ? true : false}
														label="Service Inspection Report item"
													/>
													<FormControlLabel control={
														<Checkbox onChange={(e) => {
															editItemData.items[index].is_treatment_service_report_item = e.target.checked;
															setEditItemData((prev) => ({...prev, items: editItemData.items}))
														}} />
														}
														checked={(item.is_treatment_service_report_item) ? true : false}
														label="Treatment Service Report item"
													/>
												</FormGroup>
											</Grid>
										</Paper>
										)
									}
									</>
								)
								:
								(
									<>
									{/* EDITING */}
										<Grid item xs={12}>
												<TextField
													fullWidth
													required
													label="Item name"
													type="text"
													placeholder="Item name"
													autoComplete="off"
													value={(editItemData && editItemData.item_name) ? editItemData.item_name : ""}
													onInput={(e) => {
														editItemData.item_name = e.target.value;
														setEditItemData((prev) => ({...prev, item_name: editItemData.item_name}))
													}}
												/>
										</Grid>
										<Grid item xs={12}>
											<FormGroup row={true}>
												<FormControlLabel control={
													<Checkbox onChange={(e) => setEditItemData((prev) => ({...prev, is_service_inspection_report_item: e.target.checked}))} />
													}
													checked={(editItemData && editItemData.is_service_inspection_report_item) ? true : false}
													label="Service Inspection Report item"
												/>
												<FormControlLabel control={
													<Checkbox onChange={(e) => setEditItemData((prev) => ({...prev, is_treatment_service_report_item: e.target.checked}))} />
													}
													checked={(editItemData && editItemData.is_treatment_service_report_item) ? true : false}
													label="Treatment Service Report item"
												/>
											</FormGroup>
										</Grid>
									</>
								)
							}
							<Grid container item xs={12} justifyContent="flex-end">
								<Button
									type="submit"
									color="success"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									>
									Submit
								</Button>
								<Button
									type="button"
									color="info"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none', marginLeft: 1}}
									onClick={handleCloseEditModal}
									>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
        </Paper>
      </Modal>
		</>
	);
}

export default Items;
