import React, { useState, useEffect } from 'react';
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";
import Config from '../components/Config';

/*
ProtectedRoute ensure that a user can access a scene only if
they are logged in and have the required access.
*/
const ProtectedRoute = (props) => {
  const [init, setInit] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [accessID, setAccessID] = useState(props.accessID);

  const checkAuth = () => {
    const options = {
	    credentials: 'include'
	  };

    fetch(Config.server_url + 'user/isauthenticated', options)
		.then(res => {
			if(!res.ok){
        setIsAuthenticated(false);
        return;
			}

      if(accessID){ // If minimum access id define, check with server
        checkAccess();
      } else {
        setIsAuthenticated(true);
      }
		})
		.catch(err => {
      console.log(err);
      setIsAuthenticated(false);
		});
  };

  const checkAccess = () => {
    const options = {
	    credentials: 'include'
	  };

    fetch(Config.server_url + `user/hasaccess?access_id=${accessID}`, options)
		.then(res => {
			if(!res.ok){
        setIsAuthenticated(false);
        return;
			}
      setIsAuthenticated(true);
		})
		.catch(err => {
      console.log(err);
      setIsAuthenticated(false);
		});
  };

  useEffect(() => {
		if(init) {
      checkAuth();
			setInit(false);
		}
	});

  if(isAuthenticated === undefined){
    return (<div/>);
  }

  if (isAuthenticated) {
    return (<Outlet />);
  } else {
    return (<Navigate to="/" replace/>);
  }
}

export default ProtectedRoute;
