import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Components
import wrapper from './Wrapper';
import ProtectedRoute from './ProtectedRoute';

// Scenes
import Login from '../scenes/Login';
import Reporting from '../scenes/Reporting';
import Users from '../scenes/Users';
import Customers from '../scenes/Customers';
import Locations from '../scenes/Locations';
import Items from '../scenes/Items';

class RouteManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

	}

	// renderNav = (component) => {
	// 	return wrapper(component);
	// }

	render() {
		return (
			<Routes>
				<Route path="/" element={<Login/>} />
				<Route path="/users" element={<ProtectedRoute accessID={3} />}>
					<Route path="/users" element={wrapper(<Users />)} />
				</Route>
				<Route path="/customers" element={<ProtectedRoute accessID={3} />}>
					<Route path="/customers" element={wrapper(<Customers />)} />
				</Route>
				<Route path="/locations" element={<ProtectedRoute />}>
					<Route path="/locations" element={wrapper(<Locations />)} />
				</Route>
				<Route path="/items" element={<ProtectedRoute accessID={3} />}>
					<Route path="/items" element={wrapper(<Items />)} />
				</Route>
				<Route path="/reporting" element={<ProtectedRoute />}>
					<Route path="/reporting" element={wrapper(<Reporting />)} />
				</Route>
				{
					// <ProtectedRoute path="/reporting" element={() => wrapper(<Reporting/>)} />
				}

				<Route path="*" element={() => <Navigate to="/" />} />
			</Routes>
		);
	}
}

export default RouteManager;
