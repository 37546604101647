import Config from './../components/Config';

const checkAccess = (maximumAccessLevel) => {
  if(Config.access_id <= maximumAccessLevel) {
    return true;
  } else {
    return false;
  }
};

export default {
  checkAccess
};
