import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.css';
import 'react-image-lightbox/style.css';

// import other components
import Theme from './components/Theme';
import RouteManager from './components/RouteManager';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    return (
      <Theme>
        <Router>
          <RouteManager/>
          <ToastContainer
            position="bottom-right"
      			autoClose={2000}
      			closeOnClick={false}
      			pauseOnHover={false}
      			draggable={true}
            transition={Slide}
          />
        </Router>
      </Theme>
    );
  }
}

export default App;
