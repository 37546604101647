import React, { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { toast } from 'react-toastify';

import Config from '../components/Config';

/*
Props:
- dataUrl: Path to fetch data from - If data is not empty, dataUrl will not be used
- data: Data object to be used
- keyField: The array key which holds the value
- valueField: The array key which holds the display value
- value: The selected value
- onChange: The onChange function
- default: The default row (object) -> { key: x, value: y }
- disabled: Whether to disable selection
*/
const Dropdown = (props) => {
	const [list, setList] = useState([]);

	const getData = () => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + props.dataUrl, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}
			return res.json();
		})
		.then(res => {
			if(props.default) {
				let obj = {};
				obj[props.keyField] = props.default.key;
				obj[props.valueField] = props.default.value;
				res = [obj, ...res];
			}

			setList(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	useEffect(() => {
		if(!props.data){
			getData();
		} else {
			setList(props.data)
		}
  }, [props.dataUrl]);

	return (
		<TextField
			fullWidth={(props.fullWidth) ? true : false}
			select
      label={(props.label) ? props.label : ""}
			disabled={(props.disabled) ? props.disabled : false}
			size={(props.size) ? props.size : "medium"}
			SelectProps={{
	      multiple: (props.multiple) ? true : false,
	      value: (list.length > 0) ? props.value : '',
	      onChange: props.onChange,
				MenuProps: {
					PaperProps: {
						sx: {
							maxWidth: '400px',
							maxHeight: '400px',
						}
					},
				}
	    }}
    >
			{
				list.map((item, index) => (
					<MenuItem key={index} value={item[props.keyField]} sx={{overflowX: 'auto'}}>{item[props.valueField]}</MenuItem>
				))
			}
    </TextField>
	);
}

export default Dropdown;
