import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { useTheme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { FaRegSadCry } from 'react-icons/fa'

/*
Props:
- images: Array of object image containing filename and url
- onDeleteImage: function to handle image deletion
- disableDelete: Set whether image delete feature is disabled
*/
const ImageGrid = (props) => {
	const theme = useTheme();

	const [isLightboxOpen, setIsLightboxOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);
	let images = (props.images) ? props.images.map(el => el.url) : [];

	const handleOpenLightbox = (index) => {
		setPhotoIndex(index);
		setIsLightboxOpen(true);
	};

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deleteImageID, setDeleteImageID] = useState();
  const handleOpenDeleteDialog = (id) => {
		setDeleteImageID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteImageID();
    setOpenDeleteDialog(false);
  };

	const imageStyle = {
		width: '100%',
	};

	const closeBtnStyle = {
		position: 'absolute',
		right: 0,
		top: 0,
	}

  return (
		<>
			<Grid
				container
				spacing={2}
			>
				<Grid
					container
					item
					xs={12}
					sx={{mb: 2}}
				>
					<Typography variant="h6" noWrap sx={{color: theme.palette.hint.main}}>
						<b>Gallery</b>
					</Typography>
				</Grid>
				{ (props.images && props.images.length > 0) ? (
						props.images.map((image, index) => {
							return (
								<Grid
									container
									item
									key={index}
									alignItems="center"
									justifyContent="center"
									xs={12}
									md={3}
									sx={{position: 'relative'}}
								>
									<img
										src={image.url}
										style={imageStyle}
										onClick={() => handleOpenLightbox(index)}
									/>
									{
										(!props.disableDelete) && (
											<IconButton
												aria-label="close"
												color="trans"
												size="small"
												onClick={() => handleOpenDeleteDialog(image.id)}
												style={closeBtnStyle}
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										)
									}
								</Grid>
							);
						})
					) : (
						<Grid
							container
							alignItems="center"
							justifyContent="center"
							sx={{p: 5}}
						>
							<Grid
								container
								item
								alignItems="center"
								justifyContent="center"
								sx={{mb: 2}}
							>
								<FaRegSadCry size={50}/>
							</Grid>
							<Typography noWrap>
								No images uploaded yet.
							</Typography>
						</Grid>
					)
				}
			</Grid>
			{isLightboxOpen && (
				<Lightbox
					mainSrc={images[photoIndex]}
					nextSrc={images[(photoIndex + 1) % images.length]}
					prevSrc={images[(photoIndex + images.length - 1) % images.length]}
					onCloseRequest={() => setIsLightboxOpen(false)}
					onMovePrevRequest={() =>
						setPhotoIndex((photoIndex + images.length - 1) % images.length)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % images.length)
					}
					reactModalStyle={{
						overlay: {
							zIndex: 1301 // MUI Modal zIndex is 1300, so set 1301 to overlay lightbox
						}
					}}
				/>
			)}
			<Dialog
	        open={openDeleteDialog}
	        onClose={handleCloseDeleteDialog}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
						color="error"
						onClick={() => {
							props.onDeleteImage(deleteImageID);
							handleCloseDeleteDialog();
						}}
						sx={{textTransform: 'none'}}
					>
						Delete
					</Button>
          <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImageGrid;
