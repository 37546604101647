import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import Particles from "react-tsparticles";
import Cookies from 'js-cookie';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ErrorIcon from '@mui/icons-material/Error';
import LoginIcon from '@mui/icons-material/Login';

import { toast } from 'react-toastify';

import Config from '../components/Config';

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	let navigate = useNavigate();

	const login = (e) => {
		e.preventDefault();

		const data = {
			'email': email,
			'password': password
		}

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(data)
		};

		fetch(Config.server_url + 'user/login', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			} else {
				Config.full_name = Cookies.get("full_name");
				Config.access_id = Cookies.get("access_id");
				navigate('/reporting');
				return;
			}
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	// Redirect user to reporting if already logged in
	if(Cookies.get("access_id")){
		return (<Navigate to="/reporting" replace/>);
	}

	return (
		<Container
			maxWidth={false}
			className="login-bg"
			style={{backgroundImage: `url('/login.jpg')`}}
		>
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ minHeight: '100vh' }}
				>
				<Grid item xs={12} sx={{mb: 3, zIndex: 1}}>
					<img src="/forearthlogo.png" alt="image" height="150"/>
				</Grid>
				<Grid item xs={12} sx={{zIndex: 1}}>
					<Paper
						elevation={1}
						style={{backgroundColor: 'rgba(100,100,100,0.3)', padding: 20}}
						>
						<Box
				      component="form"
				      sx={{
				        '& .MuiTextField-root': { m: 1, width: '25ch' },
				      }}
				      autoComplete="true"
							onSubmit={login}
				    >
							<Grid
								container
								direction="column"
								>
								<Grid item xs={12}>
									<TextField
										required
										label="Email"
										type="email"
										placeholder="joe@example.com"
										variant="filled"
										sx={{
						          input: {
												color: 'black',
						            background: 'white',
												borderRadius: 1,
						          }
						        }}
										value={email}
										onInput={(e) => setEmail(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										required
										label="Password"
										type="password"
										variant="filled"
										sx={{
						          input: {
												color: 'black',
						            background: 'white',
												borderRadius: 1,
						          }
						        }}
										value={password}
										onInput={(e) => setPassword(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sx={{mt: 2}}>
									<Button
										type="submit"
										variant="contained"
										color="info"
										fullWidth
										disableElevation
										>
										Login <LoginIcon fontSize="tiny" sx={{ml: 0.5}}/>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
			<Particles
				options={{
					backgroundMode: {
						enable: true,
						zIndex: 0
					},
					particles: {
						number: {
							value: 20,
							density: {
								enable: true,
								value_area: 400
							}
						},
						color: {
							value: "#FBF7E1"
						},
						shape: {
							type: ["circle", "polygon", "square"],
							polygon: {
								nb_sides: 5
							},
						},
						opacity: {
							value: 0.5,
							random: true,
							anim: {
								enable: true,
								speed: 0.2,
								opacity_min: 0.05,
								sync: false
							}
						},
						size: {
							value: 7,
							random: true,
							anim: {
								enable: true,
								speed: 0.5,
								size_min: 1,
								sync: false
							}
						},
						move: {
							enable: true,
							speed: 0.5,
							direction: "right",
							random: false,
							out_mode: "out",
						}
					},
					fps_limit: 60,
				}}
	    />
		</Container>
	);
}

export default Login;
