import React from 'react';
import {useDropzone} from 'react-dropzone';
import { toast } from 'react-toastify';

import { TiUpload } from 'react-icons/ti'

const DropzoneWrapper = (props) => {
	let files = null;

	const style = {
		border: '2px dashed rgba(0,0,150,0.1)',
		backgroundColor: 'rgba(0,0,150,0.05)',
		color: 'rgba(0,0,150,0.5)',
		padding: 10,
		paddingTop: 15,
		paddingLeft: '20px',
		paddingRight: '20px',
		minWidth: '200px',
		textAlign: 'center',
	};

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
			onDropAccepted: (files) => {
				props.onDrop(files);
			},
			onDropRejected: (files) => {
				return toast.error(<div>Invalid file uploaded.</div>)
			},
			multiple: (props.multiple) ? true : false,
			useFsAccessApi: false,
			accept:
				(props.acceptPdf) ?
				{'application/pdf': ['.pdf']} :
				(props.acceptImage) ?
				{'image/*': ['.jpeg', '.png']} :
				(props.acceptPdfAndImage) ?
				{
					'application/pdf': ['.pdf'],
					'image/*': ['.jpg', '.jpeg', '.png'],
				} :
				"*",
		})

  return (
		<div {...getRootProps()} style={style}>
			<TiUpload size={50}/>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p><b>Drop the files here to upload</b></p> :
          <p><b>{(props.message) ? props.message : "Drop or select here to upload file"}</b></p>
      }
    </div>
  );
}

export default DropzoneWrapper;
