import React, { useState, useEffect } from 'react';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const Theme = (props) => {
	const defaultTheme = createTheme();
	const theme = createTheme({
		components: {
			MuiButton: {
	      variants: [
	        {
	          props: { variant: 'pill' },
	          style: (theme: any) => {
							const currentColor = theme.theme.palette[theme.ownerState.color];

							return {
								textTransform: 'none',
		            borderRadius: `100px`,
								backgroundColor: currentColor.main,
	            	color: currentColor.contrastText,
								'&:hover': {
                    backgroundColor: currentColor.main,
										opacity: 0.9,
                },
							}
	          },
	        },
	      ],
	    },
		},
		typography: {
	    button: {
	      textTransform: 'none'
	    },
	  },
		palette: {
			hint: defaultTheme.palette.augmentColor({
	      color: { main: "#807C7C" },
	      name: "hint"
	    }),
			trans: defaultTheme.palette.augmentColor({
	      color: { main: "rgba(0,0,0,0.4)" },
	      name: "trans"
	    }),
			purple: defaultTheme.palette.augmentColor({
	      color: { main: "#7200CF" },
	      name: "purple"
	    }),
			danger: defaultTheme.palette.augmentColor({
	      color: { main: "#C90B00" },
	      name: "danger"
	    })
		}
	});

	return (
		<ThemeProvider theme={theme}>
			{props.children}
		</ThemeProvider>
	);
}

export default Theme;
