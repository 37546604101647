import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import PublicIcon from '@mui/icons-material/Public';

import { toast } from 'react-toastify';

import SignatureCanvas from 'react-signature-canvas';

import Dropdown from '../components/Dropdown';
import StripedDataGrid from '../components/StripedDataGrid';
import GridToolbarQuickFilterCustom from '../components/GridToolbarQuickFilterCustom';

import Config from '../components/Config';
import Utils from './../helper/Utils';

const Locations = () => {
	const theme = useTheme();

	let sigCanvas = useRef({});

	const [locationData, setLocationData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [dataGridPageSize, setDataGridPageSize] = useState(25);

	const [deleteLocationID, setDeleteLocationID] = useState();

	const [isAddingRecord, setIsAddingRecord] = useState(false);
	const [editLocationID, setEditLocationID] = useState();
	const [editLocationData, setEditLocationData] = useState();
	const [editTeamData, setEditTeamData] = useState([]);

	const [loadData, setLoadData] = useState(true);

	const [openEditModal, setOpenEditModal] = useState(false);
	const handleOpenAddEditModal = () => {
		setIsAddingRecord(true);
		setEditLocationData({
			location_id: 1,
		});
		setOpenEditModal(true);
  };
	const handleOpenEditModal = (id, data) => {
		setIsAddingRecord(false);
		setEditLocationID(id);

		let dataInstance = JSON.parse(JSON.stringify(data)); // Deep copy data
		setEditLocationData(dataInstance);
		setOpenEditModal(true);
		sigCanvas = [];
  };
  const handleCloseEditModal = () => {
		setEditLocationID();
		setEditLocationData();
    setOpenEditModal(false);
  };

	const [openTeamModal, setOpenTeamModal] = useState(false);
	const handleOpenTeamModal = () => {
		getTeamData();
		setOpenTeamModal(true);
  };
  const handleCloseTeamModal = () => {
		setEditTeamData([]);
    setOpenTeamModal(false);
  };

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (id) => {
		setDeleteLocationID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteLocationID();
    setOpenDeleteDialog(false);
  };

	let navigate = useNavigate();

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const buttonStyle = {
		textTransform: 'none',
		width: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const getLocationData = () => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'location/', options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			for(let item of res){
				// Get location user and place it in an array
				let locationUserArr = [];
				for(let locationUser of item.location_user){
					locationUserArr.push(locationUser.user_info_id);
				}
				item.location_user = locationUserArr; // Set location user to be user id array
			}

			// Clone and store the original data
			setLocationData(JSON.parse(JSON.stringify(res)));
			let originalData = JSON.parse(JSON.stringify(res));

			// Format data for table display
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].location_id;

				//Address
				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no, ((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : "")];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;

				// Buttons
				if(Utils.checkAccess(3)){
					arr[index].button = (
						<>
							<IconButton aria-label="edit" color="info" size="small" onClick={() => handleOpenEditModal(arr[index].location_id, originalData[index])}>
								<EditIcon fontSize="small"/>
							</IconButton>
							<IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteDialog(arr[index].location_id)}>
								<DeleteIcon fontSize="small"/>
							</IconButton>
						</>
					)
				}
			});

			// Display the table data
			setTableData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const getTeamData = () => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'team/', options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			setEditTeamData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const editTeam = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editTeamData)
		};

		fetch(Config.server_url + 'team/', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited team</div>);
			handleCloseTeamModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const addLocation = (e) => {
		e.preventDefault();

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editLocationData)
		};

		fetch(Config.server_url + 'location/', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully added location</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const editLocation = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editLocationData)
		};

		fetch(Config.server_url + 'location/' + editLocationID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited location</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const deleteLocation = () => {

		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'location/' + deleteLocationID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted location</div>);
			setLoadData(true);
			handleCloseDeleteDialog();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const columns = [
		{
	    field: 'location_id',
	    headerName: 'ID',
			flex: 0.5,
	  },
		{
	    field: 'customer_name',
	    headerName: 'Customer',
			minWidth: 150,
			flex: 1,
	  },
		{
	    field: 'team_name',
	    headerName: 'Team',
			minWidth: 150,
			flex: 1,
	  },
		{
	    field: 'address_dg',
	    headerName: 'Address',
			minWidth: 200,
			flex: 2,
	  },
		{
	    field: 'button',
	    headerName: '',
			sortable: false,
			flex: 1,
			align: 'right',
			minWidth: 75,
	    renderCell: (field) => (
				<>
						{field.value}
	      </>
	    ),
	  },
	];

	useEffect(() => {
		if(loadData) {
			getLocationData();
			setLoadData(false);
		}
	});

	return (
		<>
		<Grid
			container
			spacing={2}
			>
			<Grid container item xs={12}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" noWrap component="div">
						<b>Locations</b>
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} justifyContent="flex-end">
					{
						(Utils.checkAccess(3)) && (
							<>
								<Button
									color="warning"
									variant="pill"
									disableElevation
									sx={[{mr: {xs: 0, md: 1}}, {mb: {xs: 1, md: 0}},buttonStyle]}
									onClick={(e) => handleOpenTeamModal()}
									>
									<PublicIcon fontSize="small" sx={{mr: 1}}/> Manage Teams
								</Button>
								<Button
									color="success"
									variant="pill"
									disableElevation
									sx={buttonStyle}
									onClick={(e) => handleOpenAddEditModal()}
									>
									<AddIcon fontSize="small"/> Add Location
								</Button>
							</>
						)
					}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div style={{ height: '80vh' }}>
					<StripedDataGrid
						rows={tableData}
						columns={columns}
						pageSize={dataGridPageSize}
						onPageSizeChange={(newPageSize) =>
        			setDataGridPageSize(newPageSize)
    				}
						pagination
						rowsPerPageOptions={[25, 50, 100]}
						getRowHeight={() => "auto"}
						sx={{
							'.MuiDataGrid-columnSeparator': {
								display: 'none',
							},
							'&.MuiDataGrid-root': {
								border: 'none',
							},
							'& .MuiDataGrid-cell': {
								py: '5px',
							},
						}}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						components={{ Toolbar: GridToolbarQuickFilterCustom }}
					/>
				</div>
			</Grid>
		</Grid>

		{/* Delete Dialog */}
		<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={deleteLocation} sx={{textTransform: 'none'}}>Delete</Button>
          <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

			{/* Add/Edit form */}
			<Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3}}>
            {(isAddingRecord) ? "Add Location" : "Edit Location"}
          </Typography>

					<Box
						component="form"
						autoComplete="true"
						onSubmit={(isAddingRecord) ? addLocation : editLocation}
					>
						<Grid
							container
							spacing={2}
							>
							<Grid item xs={12}>
								<Dropdown
									dataUrl="customer"
									label="Customer"
									keyField="customer_id"
									valueField="customer_name"
									fullWidth
									value={(editLocationData && editLocationData.customer_id) ? editLocationData.customer_id : ""}
									onChange={(e) => setEditLocationData((prev) => ({...prev, customer_id: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12}>
								<Dropdown
									dataUrl="team"
									label="Team"
									keyField="team_id"
									valueField="team_name"
									fullWidth
									value={(editLocationData && editLocationData.team_id) ? editLocationData.team_id : ""}
									onChange={(e) => setEditLocationData((prev) => ({...prev, team_id: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12} sx={{mb: 2}}>
								<Dropdown
									dataUrl="user"
									label="Managed By"
									keyField="user_info_id"
									valueField="full_name"
									fullWidth
									multiple
									value={(editLocationData && editLocationData.location_user) ? editLocationData.location_user : []}
									onChange={(e) => setEditLocationData((prev) => ({...prev, location_user: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextField
									fullWidth
									required
									label="Address"
									type="text"
									placeholder="Address"
									autoComplete="off"
									value={(editLocationData && editLocationData.address) ? editLocationData.address : ""}
									onInput={(e) => {
										editLocationData.address = e.target.value;
										setEditLocationData((prev) => ({...prev, address: editLocationData.address}))
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									fullWidth
									inputProps={{ minLength: 6, maxLength: 6 }}
									label="Postal Code"
									type="tel"
									placeholder="Postal Code"
									autoComplete="off"
									value={(editLocationData && editLocationData.postal_code) ? editLocationData.postal_code : ""}
									onInput={(e) => {
										if(e.target.value.length > 6){
											return;
										}

										editLocationData.postal_code = e.target.value;
										setEditLocationData((prev) => ({...prev, postal_code: editLocationData.postal_code}))
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label="Block no."
									type="text"
									placeholder="Block no."
									autoComplete="off"
									value={(editLocationData && editLocationData.block_no) ? editLocationData.block_no : ""}
									onInput={(e) => {
										editLocationData.block_no = e.target.value;
										setEditLocationData((prev) => ({...prev, block_no: editLocationData.block_no}))
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label="Unit no."
									type="text"
									placeholder="Unit no."
									autoComplete="off"
									value={(editLocationData && editLocationData.unit_no) ? editLocationData.unit_no : ""}
									onInput={(e) => {
										editLocationData.unit_no = e.target.value;
										setEditLocationData((prev) => ({...prev, unit_no: editLocationData.unit_no}))
									}}
								/>
							</Grid>
							<Grid container item xs={12} justifyContent="flex-end">
								<Button
									type="submit"
									color="success"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									>
									Submit
								</Button>
								<Button
									type="button"
									color="info"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none', marginLeft: 1}}
									onClick={handleCloseEditModal}
									>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
        </Paper>
      </Modal>

			{/* Team form */}
			<Modal
        open={openTeamModal}
        onClose={handleCloseTeamModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Grid container sx={{mb: 3}}>
						<Grid item xs={8}>
							<Typography variant="h6" component="h2">
								Manage Teams
							</Typography>
						</Grid>
						<Grid container item xs={4} justifyContent="flex-end">
							<Button
								color="success"
								disableElevation
								sx={{textTransform: 'none'}}
								onClick={(e) => {
									editTeamData.push({
										team_id: undefined,
										team_name: undefined,
									});
									setEditTeamData([...editTeamData]);
								}}
								>
								<AddIcon fontSize="small"/> Add New
							</Button>
						</Grid>
					</Grid>

					<Box
						component="form"
						autoComplete="true"
						onSubmit={editTeam}
					>
						<Grid
							container
							spacing={2}
							>
							{
								editTeamData.map((item, index) =>
								(!item.delete) &&
								(
									<React.Fragment key={index}>
										<Grid item xs={9} md={10}>
											<TextField
												fullWidth
												required
												label="Team Name"
												type="text"
												placeholder="Name"
												autoComplete="off"
												value={item.team_name || ""}
												onInput={(e) => {
													item.team_name = e.target.value;
													editTeamData[index] = item;
													setEditTeamData([...editTeamData]);
												}}
											/>
										</Grid>
										<Grid item xs={3} md={2}>
											<Button
												aria-label="delete"
												color="error"
												size="small"
												variant="contained"
												sx={{height:"100%"}}
												disableElevation
												fullWidth
												onClick={() => {
													if(!item.team_id) {
														editTeamData.splice(index, 1);
														setEditTeamData([...editTeamData]);
													}
													else {
														editTeamData[index].delete = true;
														setEditTeamData([...editTeamData]);
													}
												}}
											>
												<CancelIcon fontSize="small"/>
											</Button>
										</Grid>
									</React.Fragment>
								))
							}
							<Grid container item xs={12} justifyContent="flex-end">
								<Button
									type="submit"
									color="success"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									>
									Submit
								</Button>
								<Button
									type="button"
									color="info"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none', marginLeft: 1}}
									onClick={handleCloseTeamModal}
									>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
        </Paper>
      </Modal>
		</>
	);
}

export default Locations;
