import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useTheme } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';

import { toast } from 'react-toastify';

import SignatureCanvas from 'react-signature-canvas';

import Dropdown from '../components/Dropdown';
import StripedDataGrid from '../components/StripedDataGrid';
import GridToolbarQuickFilterCustom from '../components/GridToolbarQuickFilterCustom';

import Config from '../components/Config';

const Users = () => {
	const theme = useTheme();

	let sigCanvas = useRef({});

	const [userData, setUserData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [dataGridPageSize, setDataGridPageSize] = useState(25);

	const [deleteUserID, setDeleteUserID] = useState();

	const [isAddingRecord, setIsAddingRecord] = useState(false);
	const [editUserID, setEditUserID] = useState();
	const [editUserData, setEditUserData] = useState();

	const [loadData, setLoadData] = useState(true);

	const [openEditModal, setOpenEditModal] = useState(false);

	const handleOpenAddEditModal = () => {
		setIsAddingRecord(true);
		setEditUserData({
			customer_id: 1,
			items: [],
			services: [],
		});
		setOpenEditModal(true);
  };
	const handleOpenEditModal = (id, data) => {
		setIsAddingRecord(false);
		setEditUserID(id);

		let dataInstance = JSON.parse(JSON.stringify(data)); // Deep copy data
		setEditUserData(dataInstance);
		setOpenEditModal(true);
		sigCanvas = [];
  };
  const handleCloseEditModal = () => {
		setEditUserID();
		setEditUserData();
    setOpenEditModal(false);
  };

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (id) => {
		setDeleteUserID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteUserID();
    setOpenDeleteDialog(false);
  };

	let navigate = useNavigate();

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const buttonStyle = {
		textTransform: 'none',
		width: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const getUserData = () => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'user/', options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			// Clone and store the original data
			setUserData(JSON.parse(JSON.stringify(res)));
			let originalData = JSON.parse(JSON.stringify(res));

			// Format data for table display
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].user_info_id;

				// Buttons
				arr[index].button = (
					<>
						<IconButton aria-label="edit" color="info" size="small" onClick={() => handleOpenEditModal(arr[index].user_info_id, originalData[index])}>
							<EditIcon fontSize="small"/>
						</IconButton>
						<IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteDialog(arr[index].user_info_id)}>
							<DeleteIcon fontSize="small"/>
						</IconButton>
					</>
				)
			});

			// Display the table data
			setTableData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const addUser = (e) => {
		e.preventDefault();

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editUserData)
		};

		fetch(Config.server_url + 'user/register', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully added user</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const editUser = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editUserData)
		};

		fetch(Config.server_url + 'user/' + editUserID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited user. Changes will take effect the next time they login.</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const deleteUser = () => {

		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'user/' + deleteUserID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted user</div>);
			setLoadData(true);
			handleCloseDeleteDialog();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const columns = [
		{
	    field: 'user_info_id',
	    headerName: 'ID',
			flex: 0.5,
	  },
		{
	    field: 'full_name',
	    headerName: 'Name',
			flex: 1,
			minWidth: 100,
	  },
	  {
	    field: 'email',
	    headerName: 'Email',
			flex: 1,
			minWidth: 200,
	  },
		{
	    field: 'access_name',
	    headerName: 'Access Level',
			flex: 1,
			minWidth: 100,
	  },
		{
	    field: 'button',
	    headerName: '',
			sortable: false,
			flex: 1,
			align: 'right',
			minWidth: 75,
	    renderCell: (field) => (
				<>
						{field.value}
	      </>
	    ),
	  },
	];

	useEffect(() => {
		if(loadData) {
			getUserData();
			setLoadData(false);
		}
	});

	return (
		<>
		<Grid
			container
			spacing={2}
			>
			<Grid container item xs={12}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" noWrap component="div">
						<b>Users</b>
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} justifyContent="flex-end">
					<Button
						color="success"
						variant="pill"
						disableElevation
						sx={buttonStyle}
						onClick={(e) => handleOpenAddEditModal()}
						>
						<AddIcon fontSize="small"/> Add User
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<div style={{ height: '80vh' }}>
					<StripedDataGrid
						rows={tableData}
						columns={columns}
						pageSize={dataGridPageSize}
						onPageSizeChange={(newPageSize) =>
        			setDataGridPageSize(newPageSize)
    				}
						pagination
						rowsPerPageOptions={[25, 50, 100]}
						getRowHeight={() => "auto"}
						sx={{
							'.MuiDataGrid-columnSeparator': {
								display: 'none',
							},
							'&.MuiDataGrid-root': {
								border: 'none',
							},
							'& .MuiDataGrid-cell': {
								py: '5px',
							},
						}}
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
						}
						components={{ Toolbar: GridToolbarQuickFilterCustom }}
					/>
				</div>
			</Grid>
		</Grid>

		{/* Delete Dialog */}
		<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" disableElevation onClick={deleteUser} sx={{textTransform: 'none'}}>Delete</Button>
          <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

			{/* Add/Edit form */}
			<Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 3}}>
            {(isAddingRecord) ? "Add User" : "Edit User"}
          </Typography>

					<Box
						component="form"
						onSubmit={(isAddingRecord) ? addUser : editUser}
					>
						<Grid
							container
							spacing={2}
							>
							<Grid item xs={12}>
								<Alert severity="info">Owner, Super Admin and Admin have the same access rights.</Alert>
							</Grid>
							<Grid item xs={12} sx={{mb: 2}}>
								<Dropdown
									dataUrl="user/accesslevel/all"
									label="Access Level"
									keyField="access_level_id"
									valueField="access_name"
									fullWidth
									value={(editUserData && editUserData.access_level_id) ? editUserData.access_level_id : ""}
									onChange={(e) => setEditUserData((prev) => ({...prev, access_level_id: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									label="Name"
									type="text"
									placeholder="Name"
									autoComplete="off"
									value={(editUserData && editUserData.full_name) ? editUserData.full_name : ""}
									onInput={(e) => {
										editUserData.full_name = e.target.value;
										setEditUserData((prev) => ({...prev, full_name: editUserData.full_name}))
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									label="Email"
									type="email"
									placeholder="Email"
									autoComplete="off"
									value={(editUserData && editUserData.email) ? editUserData.email : ""}
									onInput={(e) => {
										editUserData.email = e.target.value;
										setEditUserData((prev) => ({...prev, email: editUserData.email}))
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									required
									label="Password"
									type="password"
									placeholder="Password"
									autoComplete="off"
									value={(editUserData && editUserData.password) ? editUserData.password : ""}
									onInput={(e) => {
										editUserData.password = e.target.value;
										setEditUserData((prev) => ({...prev, password: editUserData.password}))
									}}
								/>
							</Grid>
							<Grid container item xs={12} justifyContent="flex-end">
								<Button
									type="submit"
									color="success"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									>
									Submit
								</Button>
								<Button
									type="button"
									color="info"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none', marginLeft: 1}}
									onClick={handleCloseEditModal}
									>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
        </Paper>
      </Modal>
		</>
	);
}

export default Users;
